import { Container } from "../Container";
import { Text } from "../Text";
import { FC, ReactNode, RefObject } from "react";
import {
  FontSizes,
  ResponsiveProp,
  Spacings,
} from "../../types/component.type";
import { Spacer } from "../Spacer";
import styles from "./Section.module.css";

interface SectionProps {
  rootRef?: RefObject<HTMLDivElement | null>;
  classNames?: {
    root: string;
  };
  title: string;
  sideElement?: ReactNode;
  description?: string;
  titleSize?: ResponsiveProp<FontSizes>;
  textAlign?: ResponsiveProp<"left" | "center" | "right">;
  children: ReactNode;
  variant?: "default" | "gray";
  size?: "default" | "wide";
  containerSpacing?: ResponsiveProp<Spacings>;
}

export const Section: FC<SectionProps> = ({
  rootRef,
  classNames,
  children,
  title,
  sideElement,
  description,
  titleSize = { mobile: "xl", tablet: "3xl", laptop: "4xl" },
  textAlign = { mobile: "left", tablet: "center" },
  variant = "default",
  size = "default",
  containerSpacing,
}) => {
  const rootClassName = [
    styles.root,
    variant === "gray" ? styles.bgGray : "",
    classNames?.root || "",
  ].join(" ");

  return (
    <section className={rootClassName} ref={rootRef}>
      <Container
        size={size === "wide" ? "wide" : undefined}
        spacing={size === "wide" ? "5x" : (containerSpacing ?? undefined)}
      >
        <header>
          {sideElement ? (
            <div className={styles.titleHasSideElement}>
              <Text size={titleSize} as="h2" weight="bold" align={textAlign}>
                {title}
              </Text>
              {sideElement}
            </div>
          ) : (
            <Text size={titleSize} as="h2" weight="bold" align={textAlign}>
              {title}
            </Text>
          )}
          {description && (
            <div className={styles.description}>
              <Spacer y={{ mobile: "2x", tablet: "4x" }} />
              <Text
                size={{ mobile: "sm", tablet: "lg" }}
                spacing="widest"
                align={textAlign}
              >
                {description}
              </Text>
            </div>
          )}
        </header>
        <Spacer y={{ mobile: "4x", tablet: "6x" }} />
        {children}
      </Container>
    </section>
  );
};
