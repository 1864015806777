import { Icon } from "../Icon";
import { Rating } from "../Rating";
import { Stack } from "../Stack";
import { Tag } from "../Tag";
import { Text } from "../Text";
import { TagColored } from "../TagColored";
import { WishlistButton } from "../WishlistButton";
import { CSSProperties } from "react";
import styles from "./TourHistoryCard.module.css";
import { useBreakPoints } from "../../hooks/useBreakPoints";

interface Theme {
  name: string;
  color: string;
}

export interface TourHistoryCardModel {
  id: number;
  imageUrl: string | null;
  title: string;
  priceCaption: string;
  price: string;
  point: string | null;
  themes: Theme[];
  location: string;
  hotel: string;
  hotelCount: number;
  hotelRating: number;
  flightType: string;
  airOriginTime: string;
  airDestinationTime: string;
  href: string;
  onSale: boolean;
  flightSummary: {
    airlineImage?: {
      url: string;
    };
    airlineName: string;
    destinationDepartureTimeLabel: string;
    originDepartureTimeLabel: string;
  };
}

interface TourHistoryCardProps {
  readonly model: TourHistoryCardModel;
  readonly width?: CSSProperties["width"];
  readonly inWishlist?: boolean;
  readonly onClickWishlistIcon?: () => void;
  readonly variant?: "normal" | "recommendedTourCard" | "toursWithQueryForMag";
}

export const TourHistoryCard = ({
  model,
  width,
  inWishlist,
  onClickWishlistIcon,
  variant,
}: TourHistoryCardProps) => {
  const breakPoints = useBreakPoints();
  const [destination, duration] = model.location.split("/");

  return (
    <a
      className={`${styles.root} ${variant === "recommendedTourCard" ? styles.variant__recommendedTourCard : variant === "toursWithQueryForMag" ? styles.variant__toursWithQueryForMag : ""}`}
      href={model.href}
      target="_blank"
      rel="noopener"
    >
      <section className={styles.inner} style={{ width }}>
        <figure
          className={`${styles.thumbnail} ${variant === "recommendedTourCard" ? styles.thumbnail__recommendedTourCard : variant === "toursWithQueryForMag" ? styles.thumbnail__toursWithQueryForMag : ""}`}
        >
          <div className={styles.thumbnailInner}>
            {model.imageUrl && (
              <img
                className={styles.mainImage}
                src={model.imageUrl}
                alt={model.title}
                loading="lazy"
              />
            )}
          </div>
          <div className={styles.labels}>
            {onClickWishlistIcon && (
              <div className={styles.wishlistButtonWrapper}>
                <WishlistButton
                  variant={inWishlist ? "pressed" : "default"}
                  stroke="white"
                  size="lg"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClickWishlistIcon();
                  }}
                />
              </div>
            )}

            {model.onSale && (
              <TagColored
                name="SALE"
                color="var(--color-red-30)"
                icon={<Icon icon="lightning" color="yellow-50" size="sm" />}
              />
            )}
            {model.themes.map((theme) => (
              <TagColored key={theme.name} {...theme} />
            ))}
          </div>
          <div className={styles.imageCover} />
          <div className={styles.stayInfo}>
            <Icon icon="location" size="sm" color="white" />
            <p>{destination}</p>
            <p>
              <span>/</span>
              {duration}
            </p>
          </div>
        </figure>
        <div className={styles.main}>
          <p className={styles.title}>{model.title}</p>
          <ul className={styles.items}>
            <li>
              <div className={styles.item}>
                <div className={styles.itemBody}>
                  <div className={styles.hotel}>
                    <div className={styles.hotelRating}>
                      <Rating value={model.hotelRating} />
                    </div>
                    <div className={styles.hotelNameWrap}>
                      <div className={styles.hotelName}>
                        <Text size="sm">{model.hotel}</Text>
                      </div>
                      {model.hotelCount > 1 && <Text size="sm">/ 他</Text>}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className={styles.item}>
                <div className={styles.itemBody}>
                  <div className={styles.flightInfoWrapper}>
                    <div className={styles.airlineSection}>
                      {model.flightSummary.airlineImage?.url && (
                        <img
                          src={model.flightSummary.airlineImage.url}
                          alt={model.flightSummary.airlineName}
                          className={styles.airlineImage}
                          height="20"
                        />
                      )}
                      <div className={styles.airlineName}>
                        <Text size="sm" truncate>
                          {model.flightSummary.airlineName
                            ? model.flightSummary.airlineName
                            : breakPoints.laptopWithUp
                              ? "航空会社おまかせ"
                              : "おまかせ"}
                        </Text>
                      </div>
                    </div>

                    <div className={styles.flightDetailsSection}>
                      <Tag color="disabled" variant="outline" size="sm">
                        {model.flightType}
                      </Tag>
                      <Stack align="center" spacing="2x">
                        <Stack align="center" spacing="base">
                          <Tag
                            color="disabled"
                            size="sm"
                            className={styles.flightTime}
                          >
                            行き
                          </Tag>
                          <div className={styles.flightTimeWrapper}>
                            <Text size="sm">
                              {model.flightSummary.originDepartureTimeLabel}
                            </Text>
                          </div>
                        </Stack>
                        <Stack align="center" spacing="base">
                          <Tag
                            color="disabled"
                            size="sm"
                            className={styles.flightTime}
                          >
                            帰り
                          </Tag>
                          <div className={styles.flightTimeWrapper}>
                            <Text size="sm">
                              {
                                model.flightSummary
                                  .destinationDepartureTimeLabel
                              }
                            </Text>
                          </div>
                        </Stack>
                      </Stack>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className={styles.footer}>
          <dl className={styles.price}>
            <dt>{model.priceCaption}</dt>
            <dd>{model.price}</dd>
          </dl>
          {model.point && (
            <div className={styles.point}>
              <Icon icon="point" size="sm" color="green-40" />
              <Text size="sm">
                <span>{model.point}</span>たまる
              </Text>
            </div>
          )}
        </div>
      </section>
    </a>
  );
};

TourHistoryCard.displayName = "TourHistoryCard";
