export interface AppFeatures {
  tour_list_available: boolean;
  is_testing: boolean;
  service_name: string;
  active_year: number;
  company_info: {
    company: string;
  };
}
type AppFeaturesKeys = {
  [K in keyof AppFeatures as Uppercase<K extends string ? K : never>]: K;
};

export const APP_FEATURES: AppFeaturesKeys = {
  TOUR_LIST_AVAILABLE: "tour_list_available",
  IS_TESTING: "is_testing",
  SERVICE_NAME: "service_name",
  ACTIVE_YEAR: "active_year",
  COMPANY_INFO: "company_info",
} as const;
