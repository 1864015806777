import {
  GrowthBook,
  useGrowthBook as _useGrowthBook,
  useFeatureIsOn as _useFeatureIsOn,
} from "@growthbook/growthbook-react";
import { AppFeatures } from "./constants";

export const useGrowthBook = (): GrowthBook<AppFeatures> | undefined =>
  _useGrowthBook<AppFeatures>();

export const useFeatureIsOn = (id: keyof AppFeatures & string): boolean =>
  _useFeatureIsOn<AppFeatures>(id);
