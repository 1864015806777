import { FC, ReactNode, useEffect } from "react";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { useAuth } from "@utils/auth";
import { cookies } from "@utils/cookies";
import {
  COOKIE_EXPERIMENT_DEVICE_ID,
  GROWTHBOOK_API_HOST,
  GROWTHBOOK_CLIENT_KEY,
  IS_NOT_PRODUCTION_ENV,
} from "@constants";
import { generateUUIDv7 } from "@newt/core";
import { MarketingService } from "@utils/marketing";

let gb: GrowthBook;
try {
  // Create a GrowthBook instance
  gb = new GrowthBook({
    apiHost: GROWTHBOOK_API_HOST,
    clientKey: GROWTHBOOK_CLIENT_KEY,
    enableDevMode: IS_NOT_PRODUCTION_ENV,
  });

  // Generate and set a device ID if it doesn't exist
  if (
    typeof window !== "undefined" &&
    !cookies().get(COOKIE_EXPERIMENT_DEVICE_ID)
  ) {
    const deviceId = generateUUIDv7();
    if (deviceId) {
      cookies().set(COOKIE_EXPERIMENT_DEVICE_ID, deviceId);
    }
  }

  // check environment variables just in case
  if (!GROWTHBOOK_API_HOST || !GROWTHBOOK_CLIENT_KEY) {
    throw new Error("GrowthBook client key is not defined");
  }
  gb.init();
} catch (err) {
  // eslint-disable-next-line no-console
  console.error(err);
}

interface Props {
  children: ReactNode;
}

export const GrowthBookContext: FC<Props> = (props) => {
  const { children } = props;
  const auth = useAuth();

  useEffect(() => {
    if (auth.isLoading) return;

    const deviceId = cookies().get(COOKIE_EXPERIMENT_DEVICE_ID) || "";
    const attrs = {
      ...(auth.user?.id ? { user_id: auth.user?.id } : {}),
      device_id: deviceId,
    };
    gb.setAttributes(attrs);

    gb.setTrackingCallback((experiment, result) => {
      MarketingService.logEvent.growthbookTrackExperiment({
        experiment_id: experiment.key,
        experiment_variation_id: result.key,
        experiment_device_id: deviceId,
      });
    });
  }, [auth]);

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
};
