import { UseAnonymousUser_QueryQuery } from "@graphql/types";
import { Loading, Section, useReloadOnPersistedPage } from "@newt/ui";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@utils/auth";
import { graphql } from "@utils/graphql";
import { gql } from "graphql-request";
import { FC } from "react";
import styles from "./SectionBrowsingHistory.module.css";
import { TourHistoryCarousel } from "./TourHistoryCarousel";

export const SECTION_BROWSING_HISTORY_BROWSED_TOURS_QUERY = gql`
  query SectionBrowsingHistory_browsedTours_Query {
    me {
      browsedTours(first: 12) {
        edges {
          node {
            ...TourHistoryCard
          }
        }
      }
    }
  }
`;

const _SECTION_BROWSING_HISTORY_ANONYMOUS_USER_FRAGMENT = gql`
  fragment SectionBrowsingHistory_AnonymousUser on AnonymousUser {
    browsedTours(first: 12) {
      edges {
        node {
          ...TourHistoryCard
        }
      }
    }
  }
`;

interface SectionBrowsingHistoryProps {
  browsedTours?: UseAnonymousUser_QueryQuery["anonymousUser"];
  isLoading?: boolean;
}

export const SectionBrowsingHistory: FC<SectionBrowsingHistoryProps> = ({
  browsedTours,
  isLoading,
}) => {
  const { user } = useAuth();

  const { data, isLoading: isInitialLoading } = useQuery({
    queryKey: ["browsedTour"],
    queryFn: () => graphql.SectionBrowsingHistory_browsedTours_Query(),
    enabled: user !== undefined,
  });
  const hasNoUserBrowsedTours =
    user === undefined || (data?.me && data.me.browsedTours.edges.length === 0);
  const hasNoAnonymousBrowsedTours =
    browsedTours?.me === undefined ||
    browsedTours.me?.browsedTours.edges.length === 0;

  useReloadOnPersistedPage({ enable: user !== undefined });

  if (hasNoUserBrowsedTours && hasNoAnonymousBrowsedTours) return null;

  return (
    <Section
      title="👀 最近みたツアー"
      textAlign={{
        mobile: "left",
        tablet: "left",
      }}
    >
      {(isInitialLoading || !data?.me) && (isLoading || !browsedTours) ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        <TourHistoryCarousel browsedTours={data || browsedTours} />
      )}
    </Section>
  );
};
