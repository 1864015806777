import { CarouselBanner } from "@components/common/CarouselBanner";
import { bannersQuery } from "@graphql/types";
import { Button, Section } from "@newt/ui";
import styles from "./SectionRecommendation.module.css";
import { MarketingService } from "@utils/marketing";

type SectionRecommendationProps = {
  banners: bannersQuery;
};

export const SectionRecommendation = ({
  banners,
}: SectionRecommendationProps) => {
  const handleBannerClick = (link?: string) => {
    if (!link) return;

    link = link.replace(
      "https://newt.net/webview/lottery",
      "https://newt.net/lottery"
    );

    MarketingService.logEvent.clickLink({
      link_type: "recommend_banner",
      link_url: link,
    });
    window.open(link, "_blank");
  };

  return (
    <Section
      title="🎉 NEWTのおすすめ"
      sideElement={
        <div className={styles.laptopWithUpVisible}>
          <Button
            color="default"
            variant="outline"
            href="https://newt.net/spl-all"
            target="_blank"
            width="content"
          >
            すべてみる
          </Button>
        </div>
      }
      textAlign="left"
    >
      <div className={styles.carousel}>
        <CarouselBanner
          items={banners.all.edges.map(({ node }) => node)}
          onClick={handleBannerClick}
        />
      </div>
      <div className={`${styles.tabletWithDownVisible} ${styles.spaceTop}`}>
        <Button
          color="default"
          variant="outline"
          href="https://newt.net/spl-all"
          target="_blank"
          size="sm"
        >
          すべてみる
        </Button>
      </div>
    </Section>
  );
};
