// Claudeから作成　rf. Notion @20250115 uuid v7
const DIGITS = "0123456789abcdef";

export const generateUUIDv7 = (
  originalTimestamp: number = Date.now()
): string | null => {
  try {
    const timestamp = Math.floor(originalTimestamp);
    if (
      !Number.isFinite(timestamp) ||
      timestamp < 0 ||
      timestamp > 0xffffffffffff
    ) {
      // eslint-disable-next-line no-console
      console.error("Invalid timestamp");
      return null;
    }

    // 16バイトの配列を生成
    const bytes = new Uint8Array(16);

    // タイムスタンプを48ビットで設定 (bytes 0-5)
    bytes[0] = (timestamp / 0x10000000000) & 0xff;
    bytes[1] = (timestamp / 0x100000000) & 0xff;
    bytes[2] = (timestamp / 0x1000000) & 0xff;
    bytes[3] = (timestamp / 0x10000) & 0xff;
    bytes[4] = (timestamp / 0x100) & 0xff;
    bytes[5] = timestamp & 0xff;

    // ランダムバイトを生成 (10バイト必要)
    const random = new Uint8Array(10);
    crypto.getRandomValues(random);

    // バージョン7をセット (bytes 6-7)
    bytes[6] = 0x70 | (random[0] >>> 4);
    bytes[7] = ((random[0] & 0x0f) << 4) | (random[1] >>> 4);

    // バリアントビットを設定 (bytes 8-9)
    bytes[8] = 0x80 | (random[2] >>> 2);
    bytes[9] = ((random[2] & 0x03) << 6) | (random[3] >>> 2);

    // 残りのランダムビットを設定 (bytes 10-15)
    for (let i = 4, j = 10; j < 16; i++, j++) {
      bytes[j] = random[i];
    }

    // UUID文字列に変換
    let uuid = "";
    for (let i = 0; i < 16; i++) {
      uuid += DIGITS[bytes[i] >>> 4];
      uuid += DIGITS[bytes[i] & 0x0f];
      if (i === 3 || i === 5 || i === 7 || i === 9) {
        uuid += "-";
      }
    }

    return uuid;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};
