import {
  HotelSearchPageQuery,
  SearchPageQuery,
} from "@components/pages/search/query";
import { SignInPageQuery } from "@components/pages/signIn/query";
import { TourPageQuery } from "@components/pages/tours/[tourId]/query";
import { SITE_DOMAIN } from "@constants";
import {
  MicroCMSArticle,
  Booking,
  Destination,
  Hotel,
  Tour,
} from "@graphql/types";
import { RouterQueryInput } from "@utils/router/routerQuery";

type PickedDestination = Pick<Destination, "code"> & {
  parent?: Pick<Destination, "code"> | null;
};

const WEBVIEW_PATH = "/webview";

const withWebview = (path: string): string => {
  if (typeof window === "undefined") return path;

  const { pathname } = window.location;

  if (pathname.includes(WEBVIEW_PATH) && !path.includes(WEBVIEW_PATH)) {
    if (path === "/") return WEBVIEW_PATH;
    return `${WEBVIEW_PATH}${path}`;
  }

  return path;
};

export const withQuery = (path: string, query?: RouterQueryInput): string => {
  const basePath = path.includes("?") ? path.split("?")[0] : path;
  const baseQueryParams = path.includes("?") ? path.split("?")[1] : undefined;

  if (!query && baseQueryParams === undefined) {
    return basePath;
  }

  const search = new URLSearchParams(baseQueryParams);

  if (query) {
    Object.keys(query).forEach((key) => {
      const value = query[key];

      if (value && query[key] != undefined) {
        if (Array.isArray(value)) {
          search.delete(key);
          value.forEach((v) => search.append(key, String(v)));
        } else {
          search.set(key, String(query[key]));
        }
      }
    });
  }

  if (search.toString() === "") {
    return basePath;
  }

  return `${basePath}?${search.toString()}`;
};

const withDomain = (path: string) => `${SITE_DOMAIN}${path}`;

const getRoute = (config: {
  path: string;
  absolute?: boolean;
  webview?: boolean;
  query?: SearchPageQuery | HotelSearchPageQuery;
}) => {
  return Object.values({ path: config.path })
    .map((path) => (config?.webview ? withWebview(path) : path))
    .map((path) => (config?.absolute ? withDomain(path) : path))
    .map((path) => (config?.query ? withQuery(path, config.query) : path))[0];
};

export const ROUTES = {
  home: () => "/",
  about: () => "/about",
  terms: () => "/terms",
  privacy: () => "/privacy",
  privacyOverseas: () => "/privacy/overseas",
  license: () => "/license",
  cancelPolicy: () => "/cancel_policy",
  covid19: () => "/covid-19",
  webflow: () => "/webflow",
  hotelApp: {
    top: () => "/hotel",
    search: (query?: HotelSearchPageQuery) =>
      getRoute({
        path: `/hotel/search`,
        query,
        webview: true,
      }),
    hotel: (id: Hotel["id"], query?: HotelSearchPageQuery) =>
      getRoute({
        path: `/hotel/${id}`,
        query,
        webview: true,
      }),
  },
  mag: (id?: MicroCMSArticle["id"]) => (id ? `/mag/${id}` : "/mag"),
  magArea: (destinationCode: Destination["code"]) =>
    `/mag/areas/${destinationCode}`,
  travelConditions: () => "/license/jyoken.boshu.pdf",
  search: (query?: SearchPageQuery) => withQuery(`/tour/search`, query),
  help: () => "/help",
  contact: () => "/contact",
  country: (destination: PickedDestination) =>
    `/${destination.code.toLowerCase()}`,
  city: (destination: PickedDestination) => {
    const parentCode = destination.parent?.code.toLowerCase() || "";
    return `/${parentCode}/${destination.code.toLowerCase()}`;
  },
  countrySpots: (countryCode: string) => `/${countryCode.toLowerCase()}/spot`,
  citySpots: (countryCode: string, cityCode: string) => {
    return `/${countryCode.toLowerCase()}/${cityCode.toLowerCase()}/spot`;
  },
  countrySpotDetail: (spotId: string, countryCode: string) =>
    `/${countryCode.toLowerCase()}/spot-${spotId}`,
  citySpotDetail: (spotId: string, countryCode: string, cityCode: string) => {
    return `/${countryCode.toLowerCase()}/${cityCode.toLowerCase()}/spot-${spotId}`;
  },
  tourTop: () => "/tour",
  tourCountry: (destination: PickedDestination) =>
    `/tour/${destination.code.toLowerCase()}`,
  tourCountryAirline: (destination: PickedDestination, airlineCode: string) =>
    `/tour/${destination.code.toLowerCase()}/airline-${airlineCode.toLowerCase()}`,
  tourCountryAirport: (destination: PickedDestination, airportCode: string) =>
    `/tour/${destination.code.toLowerCase()}/${airportCode.toLowerCase()}`,
  tourCountryKeyword: (destination: PickedDestination, keyword: string) =>
    `/tour/${destination.code.toLowerCase()}/${keyword}`,
  tourCity: (destination: PickedDestination) => {
    const parentCode = destination.parent?.code.toLowerCase() || "";
    return `/tour/${parentCode}/${destination.code.toLowerCase()}`;
  },
  tourCityAirline: (destination: PickedDestination, airlineCode: string) => {
    const parentCode = destination.parent?.code.toLowerCase() || "";
    return `/tour/${parentCode}/${destination.code.toLowerCase()}/airline-${airlineCode.toLowerCase()}`;
  },
  tourCityAirport: (destination: PickedDestination, airportCode: string) => {
    const parentCode = destination.parent?.code.toLowerCase() || "";
    return `/tour/${parentCode}/${destination.code.toLowerCase()}/${airportCode.toLowerCase()}`;
  },
  tourCityKeyword: (destination: PickedDestination, keyword: string) => {
    const parentCode = destination.parent?.code.toLowerCase() || "";
    return `/tour/${parentCode}/${destination.code.toLowerCase()}/${keyword}`;
  },
  tourRedirect: ({
    tourId,
    hotelId,
    query,
  }: {
    tourId: string | number;
    hotelId?: string | number;
    query?: TourPageQuery;
  }) => withQuery(`/tour/redirect`, { ...query, tourId, hotelId }),
  tour: (tourUrl: string, query?: TourPageQuery) => withQuery(tourUrl, query),
  tourUnlisted: (tourUrl: string, query?: TourPageQuery) =>
    withQuery(tourUrl, query),
  signin: {
    index: (query?: SignInPageQuery) => withQuery("/signin", query),
    phone: () => "/signin/phone",
  },
  booking: {
    tour: (tourId: Tour["id"]) => ({
      signin: {
        index: () => `/book/tour/${tourId}/signin`,
        phone: () => `/book/tour/${tourId}/signin/phone`,
      },
      index: () => `/book/tour/${tourId}`,
      traveler: (travelerId: number) =>
        `/book/tour/${tourId}/traveler/${travelerId}`,
      paymentMethod: {
        index: () => `/book/tour/${tourId}/payment-method`,
        add: () => `/book/tour/${tourId}/payment-method/add`,
        edit: (id: string) => `/book/tour/${tourId}/payment-method/${id}`,
      },
      confirm: () => `/book/tour/${tourId}/confirm`,
      thanks: (bookingId: number) =>
        withQuery(`/book/tour/${tourId}/thanks`, { bookingId }),
      checkout: () => `/book/tour/${tourId}/checkout`,
    }),
  },
  account: {
    index: () => "/account",
    wishlist: () => `/account/wishlist`,
    coupons: () => `/account/coupons`,
    profile: {
      index: () => "/account/profile",
      edit: () => "/account/profile/edit",
      phone: () => "/account/profile/phone",
      public: () => "/account/profile/public",
      email: () => "/account/profile/email",
      passport: () => "/account/profile/passport",
      delete: () => "/account/profile/delete",
    },
    departureAirport: () => "/account/departure-airport",
    paymentMethod: {
      index: () => "/account/payment-method",
      add: () => "/account/payment-method/add",
      edit: (id: string) => `/account/payment-method/${id}`,
    },
  },
  reservation: (id: Booking["id"]) => `/reservations/${id}`,
  reservations: {
    index: () => "/reservations",
    completed: () => "/reservations/completed",
    canceled: () => "/reservations/canceled",
    view: {
      tour: (id: Booking["id"]) => ({
        // index: `/reservations/${id}`,
        checkout: {
          index: () => `/reservations/${id}/checkout`,
          confirm: () => `/reservations/${id}/checkout/confirm`,
          paymentMethod: {
            index: () => `/reservations/${id}/checkout/payment-method`,
            add: () => `/reservations/${id}/checkout/payment-method/add`,
            edit: (cardSeq: string) =>
              `/reservations/${id}/checkout/payment-method/${cardSeq}`,
          },
        },
      }),
      hotel: (id: Booking["id"]) => `/hotel/reservations/${id}`,
    },
  },
  hotel: (id: Hotel["id"]) =>
    getRoute({ path: `/hotels/${id}`, webview: true }),
  hotels: (ids: Hotel["id"][]) =>
    getRoute({ path: `/hotels?ids=${ids}`, webview: true }),
  lottery: {
    index: () => getRoute({ path: `/lottery`, absolute: true }),
    ogp: (id: number | string) =>
      getRoute({ path: `/lottery/${id}`, absolute: true }),
  },
  lottery2: {
    ogp: (id: number | string) =>
      getRoute({ path: `/lottery2/${id}`, absolute: true }),
  },
  webview: {
    account: {
      paymentMethod: {
        index: () => "/webview/account/payment-method",
        add: () => "/webview/account/payment-method/add",
        edit: (id: string) => `/webview/account/payment-method/${id}`,
      },
    },
    book: {
      paymentMethod: {
        index: () => "/webview/book/payment-method",
        add: () => "/webview/book/payment-method/add",
        edit: (id: string) => `/webview/book/payment-method/${id}`,
        selected: () => `/webview/book/payment-method/selected`,
      },
    },
    insurance: {
      paymentMethod: {
        index: () => "/webview/insurance/payment-method",
        add: () => "/webview/insurance/payment-method/add",
        edit: (id: number) => `/webview/insurance/payment-method/${id}`,
        selected: () => `/webview/insurance/payment-method/selected`,
      },
    },
    lottery: {
      index: (lotteryType?: string) =>
        getRoute({
          path: "/webview/lottery",
          query: { lotteryType },
        }),
      result: (search: string = "") => `/webview/lottery/result${search}`,
    },
    hotel: (id: Hotel["id"]) =>
      getRoute({ path: `/webview/hotels/${id}`, webview: true }),
  },
  friendCode: () => "/friend-code",
  insurance: {
    index: (bookingId?: string | number) =>
      withQuery("/insurance", { bookingId }),
    traveler: (id: number) => `/insurance/traveler/${id}`,
    paymentMethod: {
      index: () => "/insurance/payment-method",
      add: () => "/insurance/payment-method/add",
      edit: (id: number) => `/insurance/payment-method/${id}`,
    },
    thanks: (bookingId: number) =>
      withQuery("/insurance/thanks", {
        bookingId,
      }),
  },
};
