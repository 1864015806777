export async function hashWithSHA256(text: string) {
  // 入力を正規化
  const trimmed = text.toLowerCase().trim();

  // TextEncoderでUTF-8バイト配列に変換
  const encoder = new TextEncoder();
  const data = encoder.encode(trimmed);

  // SHA-256でハッシュ化
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);

  // ArrayBufferを16進数文字列に変換
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}
