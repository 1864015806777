import { addPaymentInfoEvent } from "./events/addPaymentInfoEvent";
import { addToWishlistEvent } from "./events/addToWishlistEvent";
import { beginCheckoutEvent } from "./events/beginCheckoutEvent";
import { changeUserBrazeEvent } from "./events/braze/changeUserEvent";
import { registerTourWishListBrazeEvent } from "./events/braze/registerTourWishListEvent";
import { signUpBrazeEvent } from "./events/braze/signUpEvent";
import { tourBookingViewBrazeEvent } from "./events/braze/tourBookingViewEvent";
import { pageViewBrazeEvent } from "./events/braze/pageViewEvent/pageViewEvent";
import { tourDetailViewBrazeEvent } from "./events/braze/tourDetailViewEvent";
import { tourListViewBrazeEvent } from "./events/braze/tourListViewEvent";
import { tourPurchaseBrazeEvent } from "./events/braze/tourPurchaseEvent";
import { clickLinkEvent } from "./events/clickLinkEvent";
import { loginEvent } from "./events/loginEvent";
import { logoutEvent } from "./events/logoutEvent";
import { pageViewEvent } from "./events/pageViewEvent";
import { purchaseEvent } from "./events/purchaseEvent";
import { signupEvent } from "./events/signupEvent";
import { viewItemEvent } from "./events/viewItemEvent";
import { viewItemListEvent } from "./events/viewItemListEvent";
import { viewModuleEvent } from "./events/viewModuleEvent";
import { viewModuleEventToApp } from "./events/viewModuleEventToApp";
import { viewSmsVerificationEvent } from "./events/viewSmsVerificationEvent";
import { setMailNotificationEvent } from "./events/setMailNotificationEvent";
import { gtm } from "./gtm";
import { clickModuleEvent } from "./events/clickModule";
import { trackExperimentGrowthBookEvent } from "./events/growthbook/trackExperimentEvent";

export const MarketingService = {
  setUserId: (id: string | undefined) => {
    gtm.setUserId(id);
  },
  clearUserId: () => {
    gtm.clearUserId();
  },

  // Event List: https://docs.google.com/spreadsheets/d/1exk03AgyTUvoCpRF4cuZf2tx_tUEM9A3ZnV19Olyb5A/edit#gid=227100698

  logEvent: {
    // Page Events
    pageView: pageViewEvent,

    // Reservation Events
    addToWishlist: addToWishlistEvent,
    beginCheckout: beginCheckoutEvent,
    addPaymentInfo: addPaymentInfoEvent,
    purchase: purchaseEvent,

    // Auth Events
    signUp: signupEvent,
    login: loginEvent,
    logout: logoutEvent,
    viewSmsVerification: viewSmsVerificationEvent,

    // Search Events
    viewItemList: viewItemListEvent,
    viewItem: viewItemEvent,

    // Braze Events
    brazePageView: pageViewBrazeEvent,
    brazeChangeUser: changeUserBrazeEvent,
    brazeTourBookingView: tourBookingViewBrazeEvent,
    brazeTourDetailView: tourDetailViewBrazeEvent,
    brazeTourListView: tourListViewBrazeEvent,
    brazeTourPurchase: tourPurchaseBrazeEvent,
    brazeSignUp: signUpBrazeEvent,
    brazeRegisterTourWishList: registerTourWishListBrazeEvent,

    // A/B Test Events
    growthbookTrackExperiment: trackExperimentGrowthBookEvent,

    // Other Events
    clickLink: clickLinkEvent,
    clickModule: clickModuleEvent,
    viewModule: viewModuleEvent,
    viewModuleToApp: viewModuleEventToApp,
    setMailNotification: setMailNotificationEvent,
  },
};
