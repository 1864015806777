import { CreateForm, stripNulls } from "@utils/form";
import * as yup from "yup";
import { useRouter } from "next/router";
import { ROUTES } from "@routes";
import { SearchPageQuery } from "@components/pages/search/query";
import {
  FlightType,
  SeatClass,
  TimeOfDayFilter,
  TourFilterFeature,
  TourHotelGradeFilter,
  TourOrderBy,
  TravelDateFlexibility,
} from "@graphql/types";
import { useEffect } from "react";
import { SearchInitialData, useSearchData } from "./useSearchData";

export interface SearchFormValues {
  destinationIds: number[];
  airportId: string;
  from: string; // YYYY-MM-DD
  to: string; // YYYY-MM-DD
  adults: number;
  children: number;
  children_without_bed: number;
  infants: number;
  rooms: number;
  hotelGrades: TourHotelGradeFilter[];
  priceFrom: number | undefined;
  priceTo: number | undefined;
  flightTypes: FlightType[];
  seatClass: SeatClass[];
  orderBy: TourOrderBy;
  themeIds: number[];
  features: TourFilterFeature[];
  highlightedTourId: string;
  originDepartureTimes: TimeOfDayFilter[];
  destinationDepartureTimes: TimeOfDayFilter[];
  hotelsIds: number[];
  travelDateFlexibility: TravelDateFlexibility;
  airlineIds: number[];
  tagIds: number[];
}

export const DEFAULT_SEARCH_FORM_VALUES: SearchFormValues = {
  destinationIds: [],
  airportId: "",
  from: "",
  to: "",
  adults: 2,
  children: 0,
  children_without_bed: 0,
  infants: 0,
  rooms: 1,
  priceFrom: undefined,
  priceTo: undefined,
  hotelGrades: [],
  flightTypes: [],
  seatClass: [],
  orderBy: TourOrderBy.RECOMMENDED,
  themeIds: [],
  features: [],
  highlightedTourId: "",
  originDepartureTimes: [],
  destinationDepartureTimes: [],
  hotelsIds: [],
  travelDateFlexibility: TravelDateFlexibility.FIXED,
  airlineIds: [],
  tagIds: [],
};

export const hotelGradesToGrades: (
  hotelGrades: TourHotelGradeFilter[]
) => number[] = (hotelGrades) => {
  return hotelGrades.reduce((acc, hotelGrade) => {
    switch (hotelGrade) {
      case TourHotelGradeFilter.GRADE_1_2:
        return [...acc, 1, 2];
      case TourHotelGradeFilter.GRADE_3:
        return [...acc, 3];
      case TourHotelGradeFilter.GRADE_4:
        return [...acc, 4];
      case TourHotelGradeFilter.GRADE_5:
        return [...acc, 5];
      default:
        return acc;
    }
  }, [] as number[]);
};

export const searchFormValuesToQuery = (
  values: SearchFormValues
): SearchPageQuery => {
  return {
    highlightedTourId: values.highlightedTourId
      ? Number(values.highlightedTourId)
      : undefined,
    adults: values.adults,
    infants: values.infants,
    rooms: values.rooms,
    children: values.children,
    children_without_bed: values.children_without_bed,
    destinationIds: values.destinationIds,
    airportId: values.airportId ? Number(values.airportId) : undefined,
    from: values.from,
    to: values.to,
    grades: hotelGradesToGrades(values.hotelGrades), // DEPRECATED: appの強制アップデート後に削除する
    hotelGrades: values.hotelGrades, // appの強制アップデートまでgaradesと併用する
    priceFrom: values.priceFrom,
    priceTo: values.priceTo,
    flightTypes: values.flightTypes,
    seatClass: values.seatClass,
    themeIds: values.themeIds,
    features: values.features,
    orderBy: values.orderBy,
    originDepartureTimes: values.originDepartureTimes,
    destinationDepartureTimes: values.destinationDepartureTimes,
    hotelsIds: values.hotelsIds,
    travelDateFlexibility:
      values.from && values.to
        ? values.travelDateFlexibility
        : TravelDateFlexibility.FIXED,
    airlineIds: values.airlineIds,
    tagIds: values.tagIds,
  };
};

export const useSearchForm = (
  baseQuery?: SearchPageQuery,
  initialData?: SearchInitialData,
  options?: { isOpenInNewTab?: boolean; resetScrollPosition?: boolean }
) => {
  const router = useRouter();

  const defaultQuery = {
    airportCode: "TYO",
    highlightedTourId: router.query.tourId,
  };
  const { airports, isLoading } = useSearchData(initialData);
  const query = { ...defaultQuery, ...stripNulls(baseQuery) };

  const getDestinationIds = (): number[] => {
    return query.destinationIds && query.destinationIds.length > 0
      ? query.destinationIds
      : query.destinationId
        ? [query.destinationId]
        : DEFAULT_SEARCH_FORM_VALUES.destinationIds;
  };

  const onSubmit = async (values: SearchFormValues) => {
    const initDestinationIds = getDestinationIds();

    (Object.keys(values) as Array<keyof SearchFormValues>).forEach((key) => {
      if (values[key] === undefined || values[key] === "") delete values[key];
    });
    let newQuery = searchFormValuesToQuery(values);
    const isDestinationChanged =
      JSON.stringify(initDestinationIds) !==
      JSON.stringify(newQuery.destinationIds);
    const isAirportChanged = query.airportId !== newQuery.airportId;

    // もし出発日が未入力の場合は、ゆらぎ検索をしない
    if (!(newQuery.from && newQuery.to)) {
      newQuery = {
        ...newQuery,
        travelDateFlexibility: DEFAULT_SEARCH_FORM_VALUES.travelDateFlexibility,
      };
    }

    // reset hotelsIds if destinationIds are changed (順番も変わってたらresetする)
    if (isDestinationChanged) {
      newQuery = { ...newQuery, hotelsIds: undefined };
    }

    // reset airlineIds if airportId is changed
    if (isDestinationChanged || isAirportChanged) {
      newQuery = { ...newQuery, airlineIds: undefined };
    }

    if (options?.isOpenInNewTab) {
      window.open(ROUTES.search(newQuery));
    } else {
      await router.push(ROUTES.search(newQuery), undefined, {
        scroll: options?.resetScrollPosition ?? false,
      });
    }
  };

  const getAirportId = (): string => {
    return (
      query?.airportId?.toString() ??
      airports.public
        ?.find((v) => v.code === query?.airportCode)
        ?.id.toString() ??
      DEFAULT_SEARCH_FORM_VALUES.airportId
    );
  };

  const getDefaultValues = (): SearchFormValues => {
    return {
      destinationIds: getDestinationIds(),
      airportId: getAirportId(),
      from: query?.from ?? DEFAULT_SEARCH_FORM_VALUES.from,
      to: query?.to ?? DEFAULT_SEARCH_FORM_VALUES.to,
      adults: query?.adults ?? DEFAULT_SEARCH_FORM_VALUES.adults,
      children: query?.children ?? DEFAULT_SEARCH_FORM_VALUES.children,
      children_without_bed:
        query?.children_without_bed ??
        DEFAULT_SEARCH_FORM_VALUES.children_without_bed,
      infants: query?.infants ?? DEFAULT_SEARCH_FORM_VALUES.infants,
      rooms: query?.rooms ?? DEFAULT_SEARCH_FORM_VALUES.rooms,
      hotelGrades: query?.hotelGrades ?? [],
      priceFrom: query?.priceFrom ?? DEFAULT_SEARCH_FORM_VALUES.priceFrom,
      priceTo: query?.priceTo ?? DEFAULT_SEARCH_FORM_VALUES.priceTo,
      flightTypes: query?.flightTypes ?? DEFAULT_SEARCH_FORM_VALUES.flightTypes,
      seatClass: query?.seatClass ?? DEFAULT_SEARCH_FORM_VALUES.seatClass,
      orderBy: query?.orderBy ?? DEFAULT_SEARCH_FORM_VALUES.orderBy,
      themeIds: query?.themeIds ?? DEFAULT_SEARCH_FORM_VALUES.themeIds,
      features: query?.features ?? DEFAULT_SEARCH_FORM_VALUES.features,
      highlightedTourId:
        query?.highlightedTourId?.toString() ??
        DEFAULT_SEARCH_FORM_VALUES.highlightedTourId,
      originDepartureTimes:
        query?.originDepartureTimes ??
        DEFAULT_SEARCH_FORM_VALUES.originDepartureTimes,
      destinationDepartureTimes:
        query?.destinationDepartureTimes ??
        DEFAULT_SEARCH_FORM_VALUES.destinationDepartureTimes,
      hotelsIds: query?.hotelsIds ?? DEFAULT_SEARCH_FORM_VALUES.hotelsIds,
      travelDateFlexibility:
        query.from && query.to && query.travelDateFlexibility
          ? query.travelDateFlexibility
          : DEFAULT_SEARCH_FORM_VALUES.travelDateFlexibility,
      airlineIds: query?.airlineIds ?? DEFAULT_SEARCH_FORM_VALUES.airlineIds,
      tagIds: query?.tagIds ?? DEFAULT_SEARCH_FORM_VALUES.tagIds,
    };
  };

  const form = CreateForm<SearchFormValues>({
    defaultValues: getDefaultValues(),
    rules: {
      destinationIds: yup.array().of(yup.number()),
      airportId: yup.string(),
      from: yup.string(),
      to: yup.string(),
      adults: yup.number(),
      children: yup.number(),
      children_without_bed: yup.number(),
      infants: yup.number(),
      rooms: yup.number(),
      hotelGrades: yup.array(),
      priceFrom: yup.number(),
      priceTo: yup.number(),
      flightTypes: yup.array(),
      seatClass: yup.array(),
      orderBy: yup.string(),
      themeIds: yup.array(),
      features: yup.array(),
      highlightedTourId: yup.string(),
      originDepartureTimes: yup.array(),
      destinationDepartureTimes: yup.array(),
      hotelsIds: yup.array(),
      travelDateFlexibility: yup.string(),
      airlineIds: yup.array(),
      tagIds: yup.array(),
    },
    onSubmit,
  });

  useEffect(() => {
    const values = getDefaultValues();
    Object.keys(values).forEach((key) => {
      const keyName = key as keyof SearchFormValues;
      form.setValue(keyName, values[keyName] as any);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, isLoading]);

  return form;
};
