import { gtm } from "../gtm";

interface Item {
  // ツアーID
  item_id: string;
  // ツアー名
  item_name: string;
  // 商品アイテムに関連付けられた割引額
  discount?: number;
  // 通貨価格(大人一人あたり旅行代金)
  price?: number;
  // 商品の割引前金額 - 割引がない場合はpriceと同じ値になる
  original_price?: number;
  // 定常/セール
  item_brand: "regular" | "sale";
  // 商品カテゴリー
  item_category: "tour" | "insurance";
  // 目的地(目的地名)
  item_category2: string;
  // 出発地(出発空港名)
  item_category3: string;
  // カスタマーが入力した人数(大人子供乳幼児含めた合計数)
  item_category4: string;
  // カスタマーが入力した開始日(出発日) ※ Unix Timestamp
  item_category5?: string;
  // カスタマーが入力した日数(旅行日数)
  item_variant?: string;
}

export interface PurchaseEventVariables {
  // 決済手段
  payment_type: "credit_card" | "bank_transfer" | "price_keep";
  // クーポン名
  coupon: string;
  // ツアーID
  item_id: string;
  // ツアー名
  item_name: string;
  // 商品の表示金額
  price: string;
  // 商品セールタイプ
  sales_type: "regular" | "sale";
  // 商品カテゴリー
  item_category: "tour" | "insurance";
  // 出発空港
  // departure: string;
  // 目的地
  destination: string;
  // 出発日(Unix Timestamp)
  departure_date: string;
  // 旅行日数
  travel_days: string;
  // 部屋数
  rooms: string;
  // 大人人数
  adults: string;
  // 子ども（ベッドあり）人数
  children_with_beds: string;
  // 子ども（ベッドなし）人数
  children_without_beds: string;
  // 乳幼児人数
  infants: string;

  // ハッシュ化されたカスタマーのメールアドレス（SHA-256）
  sha256_email_address?: string;

  // Eコマース
  ecommerce: {
    // イベントに関連づけられた商品アイテムの通貨（ISO 4217）
    currency?: string;
    // 合計金額（諸税や比嘉料金を含めた合計額）
    value?: number;
    // 予約番号
    transaction_id: string;

    items: Item[];
  };
}

export const purchaseEvent = (variables: PurchaseEventVariables) => {
  gtm.pushEvent("purchase", variables);
};
