import { gtm } from "../../gtm";

export interface TrackExperimentGrowthBookEventVariables {
  experiment_id: string;
  experiment_variation_id: string;
  experiment_device_id: string;
}

export const trackExperimentGrowthBookEvent = (
  variables?: TrackExperimentGrowthBookEventVariables
) => {
  gtm.pushEvent("track_experiment", variables);
};
