import { TourOrderBy } from "@graphql/types";
import { gtm } from "../gtm";

type SortType = "recommended" | "price_ascending" | "price_descending";

export const getSortType = (orderBy: TourOrderBy): SortType => {
  switch (orderBy) {
    case TourOrderBy.PRICE_ASC:
      return "price_ascending";
    case TourOrderBy.PRICE_DESC:
      return "price_descending";
    case TourOrderBy.RECOMMENDED:
    default:
      return "recommended";
  }
};

export interface Item {
  // ツアーID
  item_id: string;
  // ツアー名
  item_name: string;
  // ツアーの価格
  price: number | string;
  // 商品カテゴリー
  item_category: "tour" | "hotel";
  // 目的地(目的地名)
  item_category2: string;
  // 出発地(出発空港名)
  item_category3?: string;
  // カスタマーが入力した人数(大人子供乳幼児含めた合計数)
  item_category4?: string;
  // カスタマーが入力した開始日(出発日) ※ Unix Timestamp
  item_category5?: string;
  // カスタマーが入力した日数(旅行日数)
  item_variant?: string;
}

export type ItemListId =
  | "recently_viewed"
  | "internal_search"
  | "arealp_tour_card"
  | "seo_search"
  | "spot_list";

export interface ViewItemListEventVariables {
  // ユーザーに商品アイテムが表示されたリストの ID
  item_list_id: ItemListId;
  // ユーザーに商品アイテムが表示されたリストの名前
  item_list_name: string;
  // 一人あたりの旅行代金の下限フィルター
  filter_price_from?: string;
  // 一人あたりの旅行代金の上限フィルター
  filter_price_to?: string;
  // ホテルグレードフィルター
  filter_hotel_grades?: string;
  // フライトフィルター
  filter_flight_types?: string;
  // 座席クラスフィルター
  filter_seat_classes?: string;
  // 行きの出発時間フィルター
  filter_origin_departure_times?: string;
  // 帰りの出発時間フィルター
  filter_destination_departure_times?: string;
  // 航空会社フィルター
  filter_airline_ids?: string;
  // 人気のホテルフィルター
  filter_hotel_ids?: string;
  // 特徴フィルター
  filter_features?: string;
  // タグフィルター
  filter_tag_ids?: string;
  // しぼりこみ機能が適用されているか
  is_filter_enabled?: "true" | "false";
  // 並び替えのタイプ
  sort_type?: SortType;
  // 商品カテゴリー
  item_category: "tour" | "hotel";
  // 出発空港
  departure?: string;
  // 目的地
  destination?: string;
  // ゆらぎ検索の期間範囲
  flexible_search_range?: string;
  // 出発日(Unix Timestamp)
  departure_date?: string;
  // 旅行日数
  travel_days?: string;
  // 部屋数
  rooms?: string;
  // 大人人数
  adults?: string;
  // 子ども（ベッドあり）人数
  children_with_beds?: string;
  // 子ども（ベッドなし）人数
  children_without_beds?: string;
  // 乳幼児人数
  infants?: string;
  // 検索結果ツアー件数
  number_of_search_results?: number | string;
  // 検索結果が0件かどうか
  has_no_results?: "true" | "false" | "unknown";
  // リストの読み込み回数
  list_load_count: number | "unknown";

  // Eコマース
  ecommerce: {
    items: Item[];
  };
}

export const viewItemListEvent = (variables?: ViewItemListEventVariables) => {
  gtm.pushEvent("view_item_list", variables);
};
