import type { AppProps } from "next/app";
import "@newt/ui/src/styles.css";
import "../styles.css";
import {
  DefaultHead,
  History,
  ConsentModal,
  ModalOnLoadHandler,
} from "@components/common";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "@utils/auth";
import { GrowthBookContext } from "@utils/growthbook";
import { ToastProvider } from "@newt/ui";
import { NextPageWithLayout } from "@root/types";
import { GMOMultiPaymentScript } from "@utils/gmo";
import { useDeleteBookingSession } from "@utils/useDeleteBookingSession";
import { useDeleteInsuranceFormSession } from "@utils/useDeleteInsuranceFormSession";
import { SupportChatProvider } from "@utils/chat/context/SupportChatContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  useDeleteBookingSession();
  useDeleteInsuranceFormSession();

  return (
    <>
      <DefaultHead />
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <GrowthBookContext>
            <History />
            <ModalOnLoadHandler />
            <ToastProvider>
              <ConsentModal />
              <SupportChatProvider>
                {getLayout(<Component {...pageProps} />)}
              </SupportChatProvider>
            </ToastProvider>
          </GrowthBookContext>
        </AuthProvider>
      </QueryClientProvider>
      <GMOMultiPaymentScript />
    </>
  );
}

export default MyApp;
