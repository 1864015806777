import {
  SectionBrowsingHistory_browsedTours_QueryQuery,
  UseAnonymousUser_QueryQuery,
} from "@graphql/types";
import {
  Icon,
  Loading,
  ResponsiveKeys,
  debounce,
  useBreakPoints,
  useEffectOnce,
} from "@newt/ui";
import { MarketingService } from "@utils/marketing";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { TourHistoryCard } from "../../TourHistoryCard";
import styles from "./TourHistoryCarousel.module.css";

export interface TourHistoryCarouselItem {
  readonly thumbnail: HTMLImageElement["src"];
  readonly caption?: HTMLImageElement["alt"] | null;
  readonly href?: HTMLAnchorElement["href"];
}

interface TourHistoryCarouselProps {
  browsedTours?:
    | SectionBrowsingHistory_browsedTours_QueryQuery
    | UseAnonymousUser_QueryQuery["anonymousUser"];
}

const SHOW_COUNT: Record<ResponsiveKeys, number> = {
  mobile: 2.2,
  tablet: 2.3,
  laptop: 3,
  desktop: 3,
};
const MIN_CARD_WIDTH = 310;
const ITEM_GAP = 24;

export const TourHistoryCarousel: FC<TourHistoryCarouselProps> = ({
  browsedTours,
}) => {
  const { edges = [] } = browsedTours?.me?.browsedTours ?? {};

  const { laptopWithUp, activeKey } = useBreakPoints();
  const show = SHOW_COUNT[activeKey];

  const [cursor, setCursor] = useState(0);
  const onClickLeftButton = useCallback(() => {
    setCursor((state) => Math.max(state - show, 0));
  }, [setCursor, show]);
  const onClickRightButton = useCallback(() => {
    setCursor((state) => Math.min(state + show, (edges.length ?? 0) - show));
  }, [edges, setCursor, show]);

  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const resizeObserverCallback = debounce(([element]) => {
      setContainerWidth(element.contentRect.width);
    }, 50);
    const resizeObserver = new ResizeObserver(resizeObserverCallback);

    if (divRef.current !== null) {
      resizeObserver.observe(divRef.current);
      setContainerWidth(divRef.current.clientWidth);
    }

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (laptopWithUp && divRef.current) {
      divRef.current.scrollLeft = 0;
    }
    if (!laptopWithUp) {
      setCursor(0);
    }
  }, [laptopWithUp, setCursor]);

  useEffectOnce(() => {
    if (edges.length) {
      MarketingService.logEvent.viewItemList({
        item_list_id: "recently_viewed",
        item_list_name: "最近見たツアー",
        item_category: "tour",
        list_load_count: 1,
        number_of_search_results: edges.length,
        has_no_results: edges.length === 0 ? "true" : "false",
        ecommerce: {
          items: edges.map(({ node }) => ({
            item_id: node.id.toString(),
            item_name: node.title,
            price: node.price?.price || "unknown",
            item_category: "tour",
            item_category2: node.destination.name,
            item_category3:
              node.representativePlan?.airPlan?.departureFlights[0]
                .departureAirport.name,
          })),
        },
      });
    }
  }, [edges]);

  if (edges.length === 0) {
    return null;
  }

  const cardWidth =
    containerWidth !== null
      ? Math.max(
          MIN_CARD_WIDTH,
          (containerWidth - ITEM_GAP * (Math.ceil(show) - 1)) / show
        )
      : 0;

  return (
    <div className={styles.root}>
      <div className={styles.leftButtonContainer}></div>
      <div className={styles.carousel} ref={divRef}>
        {containerWidth !== null ? (
          <ul
            className={styles.items}
            style={{
              left: laptopWithUp ? (cardWidth + ITEM_GAP) * cursor * -1 : 0,
            }}
          >
            {edges.map(({ node }) => (
              <li className={styles.item} key={node.id}>
                <TourHistoryCard
                  fragment={node}
                  width={cardWidth}
                  itemListId="recently_viewed"
                />
              </li>
            ))}
          </ul>
        ) : (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
      </div>
      <div className={styles.rightButtonContainer}>
        {cursor > 0 && (
          <button className={styles.leftButton} onClick={onClickLeftButton}>
            <Icon icon="chevronLeft" color="gray-50" />
          </button>
        )}
        {cursor < edges.length - show && (
          <button className={styles.rightButton} onClick={onClickRightButton}>
            <Icon icon="chevronRight" color="gray-50" />
          </button>
        )}
      </div>
    </div>
  );
};
